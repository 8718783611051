import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

export default function Day ({
  unavailable,
  selected,
  userDay,
  day,
  nameDay,
  numberDay,
  overlap,
  click,
}) {
  const name = day.format(nameDay || 'dddd').toLowerCase();
  const date = day.format(numberDay || 'D');
  const isToday = moment().isSame(day, 'day');
  const isPast = moment().isAfter(day, 'day');
  const formatedDate = moment(day).format('YYYY-MM-DD');

  const classes = classNames('content-day', {
    'disabled': unavailable,
    'selectable': selected,
    'past': isPast,
    'overlap': overlap,
    'active': userDay && moment(userDay, 'YYYY-MM-DD').isSame(formatedDate)
  });
  const labelClasses = classNames('cta-day', {
    'overlap': overlap,
  });

  return (
    <button
      disabled={unavailable}
      onClick={() => click(day)}
      className={labelClasses}
    >
      <div className="name-day">
        <p className={isToday ? 'isToday' : ''}>
          <FormattedMessage id={`day.${name}`} defaultMessage={name} />
        </p>
      </div>
      <div className={classes}>
        <div>{date}</div>
      </div>
    </button>
  );
}
