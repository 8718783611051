import React, { Component } from 'react';
import { recoverNavLang } from '../../utils/recoverNavLanguage';
import CallApi from './../../utils/CallApi';
import { FormattedMessage } from 'react-intl';
import Loader from 'react-loader-spinner';
import moment from 'moment-timezone';
import Header from './Header';
import { initLogRocket, startLogRocketSession } from '../../utils/Logrocket';

class EmailValidation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			date: '',
			time: '',
			nbGuest: '',
			gender: '',
			firstname: '',
			lastname: '',
			email: '',
			phone: '',
			information: '',
			errors: '',
			society: '',
			bookingId: props.match.params.id,
			confirmResponse: null,
			comment: '',
			confirmResponseNotAccept: '',
			clientLaddition: false,
			loader: false,
			restaurantName: '',
		};
	}

	componentWillMount() {
		document.body.setAttribute('id', 'admin');
		const searchParams = new URLSearchParams(window.location.search);
		const searchValues = searchParams.get('confirm');

		this.setState({
			confirmResponse: JSON.parse(searchValues),
		});
	}

	componentWillUnmount() {
		document.body.setAttribute('id', '');
	}

	async componentDidMount() {
		const {
			match: { params },
		} = this.props;

		const validation = {
			validated: this.state.confirmResponse,
		};

		const restaurant = await CallApi('get', 'restaurants', params.restaurant);
		const logRocket = restaurant[0].log_rocket;
		if(logRocket){
			initLogRocket();
			startLogRocketSession(`Booking confirm - ${params.restaurant}`, { name : restaurant[0].name });
		}

		try {
			const res = await CallApi('get', 'bookings', params.id);
			let data = res[0];
			const date = moment.utc(data.date, 'X').format('YYYY-MM-DD HH:mm');
			const dateUTC = moment.tz(date, data.restaurant.timezone);
			this.setState({
				date: moment(dateUTC).format('YYYY-MM-DD'),
				time: moment(dateUTC).format('HH:mm'),
				nbGuest: data.nbGuest,
				gender: data.user.gender,
				firstname: data.user.firstname,
				lastname: data.user.lastname,
				email: data.user.email,
				phone: data.user.phone,
				information: data.user.information,
				validated: data.validated,
				sendResponse: data.sendResponse,
				society: data.user.society,
				clientLaddition: data.restaurant.clientLaddition,
				restaurantName: data.restaurant.name,
			});

			if (!!this.state.confirmResponse && !this.state.sendResponse) {
				try {
					const sendPositiveMail = await CallApi(
						'put',
						'bookings',
						params.id,
						validation
					);
					if (sendPositiveMail[0] === 200) {
						this.setState({
							sendPositiveMailSuccess: true,
						});
					}
				} catch (e) {
					if (e.request.status === 500) {
						return this.setState({
							errors: (
								<FormattedMessage
									id="emailValidation.errorPostApi"
									defaultMessage="Oups, nous rencontrons actuellement un problème de communication avec les services l'Addition."
								/>
							),
						});
					} else {
						return this.setState({
							errors: (
								<FormattedMessage
									id="emailValidation.errorSendMail"
									defaultMessage="Oups, nous rencontrons un problème avec l'envoi du mail de confirmation"
								/>
							),
						});
					}
				}
			}
		} catch (e) {
			this.setState({
				errors: (
					<FormattedMessage
						id="emailValidation.errorGetDataBooking"
						defaultMessage="Oups, nous rencontrons un problème avec la récupération des informations de la réservation"
					/>
				),
			});
		}
	}

	handleInputChange = e => {
		e.preventDefault();
		this.setState({ comment: e.target.value });
	};

	handleSubmit = async e => {
		e.preventDefault();
		const {
			match: { params },
		} = this.props;

		const notAcceptBooking = {
			validated: false,
			comment: this.state.comment,
		};

		try {
			this.setState({
				loader: true,
			});
			const sendNotAccept = await CallApi(
				'put',
				'bookings',
				params.id,
				notAcceptBooking,
				false
			);
			if (sendNotAccept.length && sendNotAccept[0].sendResponse) {
				this.setState({
					confirmResponseNotAccept: (
						<FormattedMessage
							id="emailValidation.notConfirmation"
							defaultMessage="Votre refus a bien été pris en compte et le client vient d’en être informé par email."
						/>
					),
				});
			} else {
				this.setState({
					errors: (
						<FormattedMessage
							id="emailValidation.errorSendMail"
							defaultMessage="Oups, nous rencontrons un problème avec l'envoi du mail de confirmation"
						/>
					),
				});
			}
		} catch (e) {
			this.setState({
				errors: (
					<FormattedMessage
						id="emailValidation.errorSendMail"
						defaultMessage="Oups, nous rencontrons un problème avec l'envoi du mail de confirmation"
					/>
				),
			});
		} finally { setTimeout(() => this.setState({ loader: false }), 500)}
	};

	findTypePerson = nbGuest => {
		const lang = recoverNavLang();
		if (nbGuest === 1) {
			return lang === 'ES' ? 'persona' : 'personne';
		}
		return lang === 'ES' ? 'personas' : 'personnes';
	};

	render() {
		const {
			gender,
			lastname,
			firstname,
			email,
			phone,
			information,
			nbGuest,
			society,
			restaurantName,
		} = this.state;

		const date = moment(this.state.date, 'YYYY-MM-DD').format('DD-MM-YYYY');
		const time = moment(this.state.time, 'HH:mm:ss').format('HH:mm');
		const typePerson = this.findTypePerson(nbGuest);

		const commentBlock = (
			<form onSubmit={this.handleSubmit}>
				<h4 className="title-margin">
					<FormattedMessage
						id="emailValidation.titleComment"
						defaultMessage="Message à l’attention du client (facultatif) :"
					/>
				</h4>
				<textarea
					className="textarea"
					name="comment"
					onChange={this.handleInputChange}
					value={this.state.comment}
				/>
				<p className="info-notConfirm">
					<FormattedMessage
						id="emailValidation.notConfirmBeforeCTA"
						defaultMessage="Cliquer sur “Valider” pour finaliser le refus de la réservation. Un email sera alors envoyé au client pour l’informer de votre annulation."
					/>
				</p>
				<div className="submit">
					<button
						className={`pure-button is-red ${
							this.state.loader ? 'disabled' : ''
						}`}>
						{this.state.loader ? (
							<Loader type="ThreeDots" color="#fff" height="10" width="15" />
						) : (
							''
						)}
						<FormattedMessage id="common.validate" defaultMessage="valider" />
					</button>
				</div>
			</form>
		);

		const contentResponse = () => {
			return (
				<React.Fragment>
					<h3>
						{!!this.state.confirmResponse ? (
							<FormattedMessage
								id="emailValidation.title"
								defaultMessage="Vous avez validé la réservation suivante :"
							/>
						) : (
							<FormattedMessage
								id="emailValidation.notConfirmTitle"
								defaultMessage="Vous allez refuser la réservation suivante :"
							/>
						)}
					</h3>
					{this.state.errors ? <p>{this.state.errors} </p> : ''}
					<div className="information">
						<div>
							<p className="text-informations">
								<FormattedMessage
									id="emailValidation.summaryText"
									defaultMessage={`Pour {nbGuest} {person}`}
									values={{
										nbGuest: nbGuest,
										person: typePerson,
									}}
								/>
							</p>
							<p className="text-informations">
								<FormattedMessage
									id="emailValidation.summaryText1"
									defaultMessage={`Chez {restaurantName}`}
									values={{
										restaurantName,
									}}
								/>
							</p>
							<p className="text-informations">
								<FormattedMessage
									id="emailValidation.summaryText2"
									defaultMessage={`Le {date} à {time}`}
									values={{
										date: date,
										time: time,
									}}
								/>
							</p>

							<h4 className="title-margin">
								<FormattedMessage
									id="emailValidation.info"
									defaultMessage="Informations concernant le client :"
								/>
							</h4>
							<div>
								{society ? (
									<p className="text-informations">
										<FormattedMessage id={`contact.society`} /> {society}
									</p>
								) : (
									''
								)}
								<p className="text-informations">
									<FormattedMessage id={`contact.${gender}`} /> {lastname}{' '}
									{firstname}
								</p>
								<p className="text-informations">
									<FormattedMessage
										id="common.email.space"
										defaultMessage="Email :"
									/>{' '}
									{email}
								</p>
								<p className="text-informations">
									<FormattedMessage
										id="common.phone.space"
										defaultMessage="Téléphone :"
									/>{' '}
									{phone}
								</p>
								{information !== '' ? (
									<p className="text-informations">
										{' '}
										<FormattedMessage
											id="emailValidation.infoClient"
											defaultMessage="Informations complémentaires : "
										/>
										{information}
									</p>
								) : (
									''
								)}
							</div>

							{!this.state.confirmResponse &&
							!this.state.confirmResponseNotAccept
								? commentBlock
								: ''}

							<p className="text-informations">
								{!!this.state.confirmResponse ? (
									<FormattedMessage
										id="emailValidation.confirmation"
										defaultMessage="un mail de confirmation a été envoyé au client"
									/>
								) : (
									''
								)}
								{this.state.confirmResponseNotAccept ? (
									<p>{this.state.confirmResponseNotAccept}</p>
								) : (
									''
								)}
							</p>
						</div>
					</div>
				</React.Fragment>
			);
		};

		const bookingAlreadyModified = () => {
			return (
				<div id="bookingAlreadyModified">
					<h3 className="bold">
						<FormattedMessage
							id="emailValidation.alreadyModified"
							defaultMessage="Cette demande de réservation a déjà été"
						/>{' '}
						{!this.state.validated ? (
							<FormattedMessage
								id="emailValidation.refuse"
								defaultMessage="refusée"
							/>
						) : (
							<FormattedMessage
								id="emailValidation.approved"
								defaultMessage="validée"
							/>
						)}{' '}
						{restaurantName}
					</h3>
					<p className="info-text">
						{!!this.state.clientLaddition ? (
							<FormattedMessage
								id="emailValidation.infoCallClientLaddition"
								defaultMessage="Si vous souhaitez modifier votre réponse, vous pouvez modifier le statut de cette réservation directement sur votre application L’Addition, et le client sera automatiquement informé par email de votre nouvelle réponse, ou contactez directement le client par téléphone ou par email."
							/>
						) : (
							<FormattedMessage
								id="emailValidation.infoCallClient"
								defaultMessage="Si vous souhaitez modifier votre réponse, contactez directement le client par téléphone ou par email."
							/>
						)}
					</p>
					<div className="info-client">
						<h4>
							<FormattedMessage
								id="emailValidation.info"
								defaultMessage="Informations client :"
							/>
						</h4>
						<div>
							{society ? (
								<p className="text-informations">
									<FormattedMessage id={`contact.society`} /> {society}
								</p>
							) : (
								''
							)}
							<p className="text-informations">
								<FormattedMessage id={`contact.${gender}`} /> {lastname}{' '}
								{firstname}
							</p>
							<p className="text-informations">
								<FormattedMessage
									id="common.email.space"
									defaultMessage="Email :"
								/>{' '}
								{email}
							</p>
							<p className="text-informations">
								<FormattedMessage
									id="common.phone.space"
									defaultMessage="Téléphone :"
								/>{' '}
								{phone}
							</p>
							<p className="text-informations">
								<FormattedMessage
									id="emailValidation.summaryText"
									defaultMessage={`Pour {nbGuest} {person}`}
									values={{
										nbGuest: nbGuest,
										person: typePerson,
									}}
								/>
							</p>
							<p className="text-informations">
								<FormattedMessage
									id="emailValidation.summaryText1"
									defaultMessage={`Chez {restaurantName}`}
									values={{
										restaurantName,
									}}
								/>
							</p>
							<p className="text-informations">
								<FormattedMessage
									id="emailValidation.summaryText2"
									defaultMessage={`Le {date} à {time}`}
									values={{
										date: date,
										time: time,
									}}
								/>
							</p>
						</div>
					</div>
				</div>
			);
		};

		const titleHeader = () => {
			if (!!this.state.confirmResponse && !this.state.sendResponse) {
				return 'validationBooking';
			}
			if (!this.state.confirmResponse && !this.state.sendResponse) {
				return 'notConfirmBooking';
			}
			return 'alreadyDealt';
		};

		return (
			<div id="booking-validation">
				<Header title={titleHeader()} displayMenu={false} />

				<div className="container">
					{!!this.state.sendResponse
						? bookingAlreadyModified()
						: contentResponse()}
				</div>
			</div>
		);
	}
}

export default EmailValidation;
