import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { FormattedMessage } from 'react-intl';
import Day from './Day';

const moment = extendMoment(Moment);

class CalendarUi extends Component {
  static contextTypes = {
    router: PropTypes.object
  };
  constructor(props) {
    super(props);
    const { userDay } = this.props
    const startDay = moment(!!userDay ? userDay : Date.now());
    const calendarStartDay = startDay.startOf('isoWeek').isoWeekday(1);

    this.state = {
      selectedDays: [],
      startDay: calendarStartDay,
      openingHours: props.openingHours,
      unavailables: false,
      closeDay: props.closeDay,
      beforeToday: false,
      listClosingDays: [],
      menuDay: props.menuDay,
      isMobile: props.isMobile,
      disableToday: props.disableToday,
      displayAllPeriod: props.displayAllPeriod,
      disablePeriod: props.disablePeriod,
      validationDelay: props.validationDelay
    };
  }

  componentDidMount() {
    const {
      closingDays,
      menuDay,
      disableAppPeriod,
      disableApp,
      openingHours
    } = this.props;
    let state = {
      ...this.state
    };
    let dates = [];
    // disabled closing days
    if (closingDays) {
      closingDays.map((range) => {
        let startRange = moment(range.day1, 'YYYY-MM-DD').subtract(1, 'days');
        let endRange = moment(range.day2, 'YYYY-MM-DD').add(1, 'days');
        //push list of all days in unvablables
        while (startRange.add(1, 'days').diff(endRange) < 0) {
          dates.push(moment(startRange).format('YYYY-MM-DD'));
        }
      });

      if (!!this.props.disableToday) {
        dates.push(new moment().format('YYYY-MM-DD'));
      }
    }
    if (!!this.props.disableTomorrow) {
      dates.push(new moment().add('1', 'days'));
    }

    // if disableApp + disableApp with period + opening hour of disable app has only 1 period -> add to closingDays
    if (
      disableApp &&
      disableAppPeriod &&
      disableAppPeriod.date &&
      disableAppPeriod.period
    ) {
      const disableAppDate = disableAppPeriod.date;
      const selectedDateName = moment(disableAppDate, 'YYYY-MM-DD')
        .format('dddd')
        .toLowerCase();
      if (openingHours[selectedDateName].length < 2) {
        dates.push(disableAppDate);
      }
    }

    this.setState({
      unavailables: dates,
      listClosingDays: state.listClosingDays
    });

    /**
     * if one date is already selected, display the current week of the selected date
     */
    if (menuDay) {
      const day = moment(menuDay).format('YYYY-MM-DD');
      const currentWeek = moment(day)
        .startOf('isoWeek')
        .isoWeekday(1);
      this.setState({
        selectedDays: [menuDay],
        startDay: currentWeek
      });
    }
  }

  daySelect = day => {
    this.setState({
      selectedDays: [day]
    });
    this.props.onUpdate(day);
    if (
      !this.state.isMobile ||
      (!!this.state.isMobile && !this.state.displayAllPeriod)
    ) {
      //
      // function donner la date
      if (this.props.isMobile) {
        this.props.updateNextDate(moment(day).format('YYYY-MM-DD'));
        this.props.closePopup();
      } else {
        this.context.router.history.push('/couverts');
      }
    } else {
      this.props.closePopup();
    }
  };

  checkSelectedDay(day) {
    let selected = false;
    if (this.state.selectedDays.length > 0) {
      this.state.selectedDays.forEach((d, i) => {
        if (day.isSame(d, 'day')) {
          selected = true;
        }
      });
    }
    return selected;
  }

  /**
   * handle unavailables days
   * @param {} day
   */
  checkUnavailables(day) {
    const {
      closeDay,
      unavailables,
      beforeToday,
      openingHours,
      validationDelay
    } = this.state;
    let unavailable = false;
    const nameOfDay = moment(day, 'YYYY-MM-DD')
      .format('dddd')
      .toLowerCase();

    // if is in closing dates
    if (unavailables) {
      unavailables.map((d, i) => {
        if (day.isSame(moment(d), 'day')) {
          unavailable = true;
        }
      });
    }

    // before today
    if (!beforeToday) {
      const dayTime = day.format('dddd').toLowerCase();
      const dayOpeningHours = openingHours[dayTime];
      const lastHourAvailable =
        dayOpeningHours && dayOpeningHours.length
          ? 
            dayOpeningHours[dayOpeningHours.length - 1].time2

          : '';

      if (lastHourAvailable) {
        const DateMonthDay = day.format('YYYY-MM-DD');
        const fullDate = moment(`${DateMonthDay} ${lastHourAvailable}`);
        const nowWithDelay = moment().add(validationDelay, 'm');

        if (nowWithDelay.isAfter(fullDate)) {
          unavailable = true;
        }
      } else {
        unavailable = true;
      }
    }

    // if empty time in opening hours (name of day)
    if (closeDay) {
      closeDay.map(nameDate => {
        if (nameDate.toLowerCase() === nameOfDay) {
          unavailable = true;
        }
      });
    }

    return unavailable;
  }

  removeTime = (time, timeLess) => {
    const isOldSchedule = this.checkOldSchedule();
    if (isOldSchedule) {
      window.localStorage.removeItem('info');
      window.location.reload();
      return;
    }

    const timeSplit = time.split(':');
    const [hours, minutes] = timeSplit;
    return moment()
      .hour(hours)
      .minutes(minutes)
      .subtract(timeLess, 'm')
      .format('HH:mm');
  };

  checkOldSchedule = () => {
    const info = window.localStorage.getItem('info');
    if (info) {
      const parseInfo = JSON.parse(info);
      const isOld = Object.keys(parseInfo.openingHours).some(key => {
        if (parseInfo.openingHours[key].length) {
          const openingDate = Object.keys(parseInfo.openingHours[key][0]);
          return openingDate[0] === 'morning' || openingDate[0] === 'evening';
        }
        return false;
      });
      return isOld;
    } else {
      return false;
    }
  };

  /**
   * display previous week
   */
  handlePrevWeek = (e) => {
    e.preventDefault();
    const { startDay, selectedDays } = this.state;
    const weekBefore = startDay.clone().subtract(7, 'days');
    const isPast = moment().subtract(7, 'days').isAfter(weekBefore);
    if (isPast) {
      return;
    }
    const previousWeekStart = weekBefore.startOf('isoWeek').isoWeekday(1)
    this.setState({ startDay: previousWeekStart },
      () => {
        if (this.props.onPrevClick) {
          this.props.onPrevClick(startDay, selectedDays);
        }
      }
    );
  };

  /**
   * display next week
   */
  handleNextWeek = (e) => {
    e.preventDefault();
    const weekAfter = this.state.startDay.clone().add(7, 'days');
    const startDay = weekAfter.startOf('isoWeek').isoWeekday(1);
    this.setState({ startDay });
  };

  handleMonth = (e, action) => {
    e.preventDefault();
    let startDay;
    if (action === 'remove') {
      const monthBefore = moment(this.state.startDay).subtract(1, 'months');
      const isPast = moment().startOf('month').isAfter(monthBefore);
      if (isPast) {
        return;
      }

      startDay = monthBefore;
    } else {
      startDay = moment(this.state.startDay).add(1, 'months');
    }

    let startOfMonth = moment(startDay).startOf('month');
    const nowIsAfterStartMonth = moment().isSameOrAfter(startOfMonth);
    if (nowIsAfterStartMonth) {
      startOfMonth = moment().startOf('isoWeek').isoWeekday(1);
    }

    this.setState({
      startDay: startOfMonth
    });
  };

  dayAsInt(momentDate) {
    const day = momentDate.format('DD');
    return +day;
  }
  
  getMonth(date) {
    return moment(date).format('MMMM').toLowerCase();
  }

  /**
   * render each days of week
   * @param {} weekdays
   */
  renderView(weekdays) {
    const dayComps = weekdays.map((day, i) => {
      // Check if inbetween two month (eg. 30, 31, 1, 2, 3, 4, 5)
      const overlap = this.dayAsInt(weekdays[i]) < this.dayAsInt(weekdays[0]);
      return <Day
        {...this.props}
        day={day}
        key={i}
        click={e => this.daySelect(e, day)}
        selected={this.checkSelectedDay(day)}
        unavailable={this.checkUnavailables(day)}
        userDay={this.props.userDay}
        overlap={overlap}
      />
    });
    return <div id="calendar-bloc">{dayComps}</div>;
  }

  render() {
    const startDay = moment(this.state.startDay);
    const weekdays = [];

    for (let index = 0; weekdays.length < 7; index++) {
      let day = startDay.clone().add(index, 'days');
      weekdays.push(day);
    }

    const overlap = this.dayAsInt(weekdays[0]) > this.dayAsInt(weekdays[6]);
    let nameMonth;
    if (overlap) {
      const m1 = this.getMonth(weekdays[0]);
      const m2 = this.getMonth(weekdays[6]);
      nameMonth = <>
        <FormattedMessage
          id={`month.${m1}`}
          defaultMessage={m1}
        />
        /
        <FormattedMessage
          id={`month.${m2}`}
          defaultMessage={m2}
        />
      </>;
    } else {
      const m = this.getMonth(weekdays[0]);
      nameMonth = <FormattedMessage
        id={`month.${m}`}
        defaultMessage={m}
      />
    }

    const monthBefore = moment(this.state.startDay).subtract(1, 'months');
    const isPastMonth = moment().startOf('month').isAfter(monthBefore);
    const weekBefore = startDay.clone().subtract(7, 'days');
    const isPastWeek = moment().subtract(7, 'days').isAfter(weekBefore);

    return (
      <div className="container pickerDate">
        <div id="month">
          <div
            onClick={e => this.handleMonth(e, 'remove')}
            className={`cta-prev-month ${isPastMonth ? 'disabled' : ''}`}
          />
          <p>
            {nameMonth}{' '}
            {moment(weekdays[0]).format('YYYY')}
          </p>
          <div
            onClick={e => this.handleMonth(e, 'add')}
            className="cta-next-month"
          />
        </div>
        <div id="days">
          <div
            onClick={this.handlePrevWeek}
            className={`cta-prev ${isPastWeek ? 'disabled' : ''}`}
          />
          {this.renderView(weekdays)}
          <div
            onClick={this.handleNextWeek}
            className={`cta-next`}
          />
        </div>
      </div>
    );
  }
  static defaultProps = {
    openingHours: [],
    validationDelay: 0,
    isMobile: false,
    displayAllPeriod: true,
    disableToday: false,
    disableTomorrow: false
  };
}

CalendarUi.propTypes = {
  closingDays: PropTypes.array,
  openingHours: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  menuDay: PropTypes.string,
  closeDay: PropTypes.array,
  isMobile: PropTypes.bool.isRequired,
  displayAllPeriod: PropTypes.bool.isRequired,
  disableToday: PropTypes.bool.isRequired,
  disableTomorrow: PropTypes.bool.isRequired,
  userDay: PropTypes.string,
  closePopup: PropTypes.func,
  disablePeriod: PropTypes.object,
  updateNextDate: PropTypes.func
};

export default CalendarUi;
